import AdvantageGroupCollection from "../backbone/model/AdvantageGroupCollection";

export interface Advantage {
  id_advantage?: string;
  name?: string;
  description?: string;
  next?: boolean;
  current?: boolean;
  previous?: boolean;
  available_to?: string;
  advantage_name?: string;
  hide_on_cart?: boolean;
  hide_on_account?: boolean;
  available_from?: string;
  icon?: string;
  error_message?: string;
  display_voucher?: boolean;
  voucher?: string;
  id_picture?: string;
  available?: boolean;

  unavailable_status?: number;

  end_date_web?: string;
  end_date_neolane?: null | string;
  date_use?: string;
  allowed_stores?: string[];

  id_product?: string;
}

export interface AdvatageGroup {
  name?: string;
  description?: string;
  advantages?: Advantage[];
}

export interface IOrder {
  completed?: boolean;
  date_add?: string;
  date_change_status?: string;
  delivery_date_desired?: string;
  delivery_date_desired_max?: string;
  delivery_date_desired_min?: string;
  express_checkout?: boolean;
  has_invoice?: boolean;
  id_carrier?: string;
  id_cart?: string;
  id_currency?: string;
  id_customer?: string;
  id_lang?: string;
  id_order?: string;
  id_order_state?: string;
  id_store?: string;
  in_progress?: boolean;
  invoice_date?: string;
  is_web?: boolean;
  like_customer?: number;
  max_product_price?: string;
  name?: string;
  nbr_product?: number;
  now?: number;
  pourcent?: string;
  products?: string[];

  products_quantity?: number;
  reorder_available?: boolean;
  status_name?: string;
  total_paid?: string;
  total_paid_real?: string;
  total_product_ttc?: string;
  total_product_tva?: string;
  vae?: number;
}

export interface CustomerCard {
  brand: string;
  cardNum: string;
  expiryDate: string;
  id_card: string;
  used: boolean;
  storing?: boolean;
  temporary?: boolean;
}

export enum ECustomerGroup {
  NOT_CONNECTED = "1",
  ALL = "2",
  CONNECTED = "3",
  ICHI = "4",
  NI = "5",
  SAN = "6",
}

export interface CustomerData {
  advantageGroups?: AdvatageGroup[];
  art?: boolean;
  birthday?: string;
  captainWalletUrl?: string;
  card?: CustomerCard[];
  children?: number;
  cinema?: boolean;
  comein?: boolean;
  customer_loyalty?: number;
  customer_loyalty_cagnotte?: number;
  date_add?: string;
  discounts?: any[];
  email?: string;
  firstname?: string;
  groups?: ECustomerGroup[];
  id_customer?: string;
  id_gender?: string;
  lastname?: string;
  likes?: string[];
  mail_alert?: number;
  marital_status?: number;
  musique?: boolean;
  newsletter?: boolean;
  optin?: number;
  ordering?: IOrder[];
  orders?: IOrder[];
  orderState?: { nbr_order?: string; order_sum?: string };
  orders_stores?: string[];
  orders_addresses?: string[];
  phone?: string;
  phoneOrder?: string[];
  series?: boolean;
  shopLike?: number;
  shopping?: boolean;
  sms?: number;
  sport?: boolean;
  NeolaneParams?: any;
  tax_indentification_number?: number;
  id_address_primary?: string;
  segmentation?: string;
  id_customer_comin?: string;
  // ---
  advantageGroupsCollection?: AdvantageGroupCollection;
  usableAdvantagesOnCart?: any;
  usableAdvantages?: any;
  mouvements?: Array<{
    amount: string;
    origine: string;
    date_add: string;
    type: string;
    store_name: string;
    solde: string;
  }>;

  customer_loyalty_history?: Array<{
    date_add: string;
    type: number;
    description?: string;
    quantity: number;
  }>;
  sponsorship?: {
    code: string;
    active?: string;
    display_name?: string;
    sponsor_description?: string;
    nbr_used?: string;
    free_use?: string;
  };
}
